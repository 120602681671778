$(function(){
	/*トップ新着情報スライダー*/
	function sliderSetting(){
		var width = $(window).width();
		if(width <= 1020){
			$('.sp-slider').not('.slick-initialized').slick({
				autoplay: false,
				autoplaySpeed: 4000,
				dots: false,
				arrows: true,
				slidesToShow:1,
				slidesToScroll:1,
				centerPadding: "10px",
			});
		} else {
			$('.sp-slider.slick-initialized').slick('unslick');
		}
	}
	sliderSetting();
	$(window).resize( function() {
		sliderSetting();
	});


	var slider2 = new Swiper ('.slider2', {
		zoom: false,
		loop: true,
		paginationClickable:true,
		paginationHide:true,
		pagination: {
		el: '.swiper-pagination',
		clickable: true,
		},
  		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		autoplay: {
			delay: 5000,
		},
		breakpoints: {
			// 画面幅が 0px 以上の場合（window width >= 640px）
			0: {
			slidesPerView: 1,
			spaceBetween: 10
			},
			// 画面幅が 980px 以上の場合（window width >= 980px）
			980: {
			slidesPerView: 4,
			spaceBetween: 10
			}
		},
	});

	/*ページ内リンクスムーススクロール*/
	var windowWidth = $(window).width();
	var windowSm = 1024; // スマホに切り替わる横幅
	if (windowWidth <= windowSm) {
	  var headerHight = 87; // スマホヘッダーの高さ分の数値を入れる
	} else {
	  var headerHight = 140; // PCヘッダーの高さ分の数値を入れる
	}
	$('a[href^="#"]').click(function() {
	  var speed = 1000;
	  var href = $(this).attr("href");
	  var target = $(href == "#" || href == "" ? "html" : href);
	  var position = target.offset().top - headerHight;
	  $("body,html").animate({ scrollTop: position }, speed, "swing");
	  return false;
	});


	//上部画像の設定
	$('.gallery').slick({
		infinite: true, //スライドをループさせるかどうか。初期値はtrue。
		fade: true, //フェードの有効化
		arrows: true,//左右の矢印あり
		prevArrow: '<div class="slick-prev"></div>',//矢印部分PreviewのHTMLを変更
		nextArrow: '<div class="slick-next"></div>',//矢印部分NextのHTMLを変更
	});

	//選択画像の設定
	$('.choice-btn').slick({
		infinite: true, //スライドをループさせるかどうか。初期値はtrue。
		slidesToShow: 6, //表示させるスライドの数
		focusOnSelect: true, //フォーカスの有効化
		asNavFor: '.gallery', //連動させるスライドショーのクラス名
	});

	//下の選択画像をスライドさせずに連動して変更させる設定。
	$('.gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		var index = nextSlide; //次のスライド番号
		//サムネイルのslick-currentを削除し次のスライド要素にslick-currentを追加
		$(".choice-btn .slick-slide").removeClass("slick-current").eq(index).addClass("slick-current");
	});

	/* パスワードの表示・非表示切替 */
	$(".toggle-password").click(function() {
		// iconの切り替え
		$(this).toggleClass("fa-eye fa-eye-slash");

		// 入力フォームの取得
		var input = $(this).parent().prev("input");
		// type切替
		if (input.attr("type") == "password") {
		input.attr("type", "text");
		} else {
		input.attr("type", "password");
		}
	});

	//フォームにて選択したファイルのファイル名を出力する
	// $('.fileup1 input').on('change', function () {
	// 	var file = $(this).prop('files')[0];
	// 	$('p.fileup-name1').text(file.name);
	// });
	// $('.fileup2 input').on('change', function () {
	// 	var file = $(this).prop('files')[0];
	// 	$('p.fileup-name2').text(file.name);
	// });
	// $('.fileup3 input').on('change', function () {
	// 	var file = $(this).prop('files')[0];
	// 	$('p.fileup-name3').text(file.name);
	// });

	//クリック/タップする度にクラスを追加・削除させる
	// $('.conditions').on('click', function(){
	// 	$('.list-conditions').toggleClass('list-active');
	// });
	// $('.article-like').on('click', function(){
	// 	$('.article-like').toggleClass('is-liked');
	// });


});//end document ready

