$(function () {
//初期は非表示
$("#backtop").hide();
	$(window).scroll(function () {
		//100pxスクロールしたら
		if ($(this).scrollTop() > 100) {
			//フェードインで表示
			$('#backtop').fadeIn();
		} else {
			$('#backtop').fadeOut();
		}
	});
	//ここからクリックイベント
	$('#backtop a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});
