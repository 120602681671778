$(function(){
   $('nav li a').each(function(){
      // リンク先のURLを取得
      var url = $(this).attr('href');
      // 現在表示されているページのURLを取得し、リンク先のURLと照合
      if(location.href.match(url)) {
         // マッチすれば、「class="active"」を付加
         $(this).addClass('active');
         // マッチしなければ、「class="active"」を削除
      } else {
         $(this).removeClass('active');
      }
   });
});


$(function(){
   $('.submenu li a').each(function(){
      // リンク先のURLを取得
      var url = $(this).attr('href');
      // 現在表示されているページのURLを取得し、リンク先のURLと照合
      if(location.href.match(url)) {
         // マッチすれば、「class="active"」を付加
         $(this).addClass('active');
         // マッチしなければ、「class="active"」を削除
      } else {
         $(this).removeClass('active');
      }
   });
});