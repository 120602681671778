$(function () {
    $(".js-custom-placeholder")
        .find("[data-placeholder]")
        .each(function () {
            // placeholderの要素作成
            var placeholder = $("<div>")
                .addClass("placeholder")
                .html($(this).attr("data-placeholder").replace(/¥n/g, "<br>"));
            $(this).after(placeholder);
            // アクセス時点で値が入力されている場合、placeholderを消しておく
            is_value_empty($(this));
        });
    $(".js-custom-placeholder")
        .find("[data-placeholder]")
        .on("focus", function () {
            $(this).next().hide();
        });
    $(".js-custom-placeholder")
        .find("[data-placeholder]")
        .on("blur", function () {
            is_value_empty($(this));
        });
});

function is_value_empty($element) {
    if ($element.val() == "") {
        $element.next().show();
    } else {
        $element.next().hide();
    }
}
